export default function LockIcon() {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.64583 4.58341C3.64583 2.73096 5.14754 1.22925 7 1.22925C8.85245 1.22925 10.3542 2.73096 10.3542 4.58341V5.23308C10.3542 5.27205 10.3491 5.30983 10.3395 5.34579C11.1772 5.65361 11.8302 6.32944 12.1072 7.18212C12.25 7.62146 12.25 8.16433 12.25 9.25008C12.25 10.3358 12.25 10.8787 12.1072 11.318C11.8187 12.206 11.1226 12.9022 10.2346 13.1907C9.79529 13.3334 9.25241 13.3334 8.16667 13.3334H5.83332C4.74758 13.3334 4.20471 13.3334 3.76537 13.1907C2.87742 12.9022 2.18126 12.206 1.89275 11.318C1.75 10.8787 1.75 10.3358 1.75 9.25008C1.75 8.16433 1.75 7.62146 1.89275 7.18212C2.1698 6.32944 2.82277 5.65361 3.66049 5.34579C3.65093 5.30983 3.64583 5.27205 3.64583 5.23308V4.58341ZM4.52083 5.18666C4.85318 5.16675 5.27025 5.16675 5.83333 5.16675H8.16667C8.72975 5.16675 9.14682 5.16675 9.47917 5.18666V4.58341C9.47917 3.21421 8.36921 2.10425 7 2.10425C5.63079 2.10425 4.52083 3.21421 4.52083 4.58341V5.18666ZM7 7.64591C7.24162 7.64591 7.4375 7.84179 7.4375 8.08341V10.4167C7.4375 10.6584 7.24162 10.8542 7 10.8542C6.75838 10.8542 6.5625 10.6584 6.5625 10.4167V8.08341C6.5625 7.84179 6.75838 7.64591 7 7.64591Z'
        fill='#4E5BA6'
      />
    </svg>
  );
}

export default function MailIcon() {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.36113 4.23886C1.16675 4.96218 1.16675 5.96005 1.16675 7.5C1.16675 9.68734 1.16675 10.781 1.72378 11.5477C1.90368 11.7953 2.12143 12.0131 2.36904 12.193C3.13573 12.75 4.2294 12.75 6.41675 12.75H7.58341C9.77076 12.75 10.8644 12.75 11.6311 12.193C11.8787 12.0131 12.0965 11.7953 12.2764 11.5477C12.8334 10.781 12.8334 9.68734 12.8334 7.5C12.8334 5.95492 12.8334 4.95554 12.6371 4.23164L11.4105 5.45825C10.4583 6.41042 9.71214 7.1566 9.05165 7.66054C8.37561 8.17634 7.73818 8.47938 7 8.47938C6.26182 8.47938 5.62439 8.17634 4.94835 7.66053C4.28786 7.1566 3.54168 6.41041 2.58952 5.45824L1.449 4.31772L1.36113 4.23886Z'
        fill='#4E5BA6'
      />
      <path
        d='M1.75008 3.41667L1.81357 3.46921L2.05101 3.68229L3.18457 4.81585C4.16554 5.79682 4.8708 6.50077 5.47911 6.96489C6.07732 7.42131 6.53006 7.60438 7 7.60438C7.46994 7.60438 7.92269 7.42131 8.5209 6.96489C9.1292 6.50077 9.83446 5.79682 10.8154 4.81586L12.1617 3.46958L12.235 3.39671C12.0551 3.1491 11.8787 2.98693 11.6311 2.80703C10.8644 2.25 9.77076 2.25 7.58341 2.25H6.41675C4.2294 2.25 3.13573 2.25 2.36904 2.80703C2.12143 2.98693 1.92998 3.16906 1.75008 3.41667Z'
        fill='#4E5BA6'
      />
    </svg>
  );
}

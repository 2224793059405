export default function EditIcon({ onClick }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M10 16.6667H17.5M13.75 2.91667C14.0815 2.58515 14.5312 2.3989 15 2.3989C15.2321 2.3989 15.462 2.44462 15.6765 2.53346C15.891 2.6223 16.0858 2.75251 16.25 2.91667C16.4142 3.08082 16.5444 3.2757 16.6332 3.49017C16.722 3.70465 16.7678 3.93452 16.7678 4.16667C16.7678 4.39881 16.722 4.62869 16.6332 4.84316C16.5444 5.05764 16.4142 5.25251 16.25 5.41667L5.83333 15.8333L2.5 16.6667L3.33333 13.3333L13.75 2.91667Z'
        stroke='#98A2B3'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

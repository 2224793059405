export default function LogoIcon({ fillColor }) {
  return (
    <svg
      width='33'
      height='31'
      viewBox='0 0 33 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 31C25.6127 31 33 24.0604 33 15.5C33 6.93959 25.6127 0 16.5 0C7.38728 0 0 6.93959 0 15.5C0 24.0604 7.38728 31 16.5 31ZM21.6474 7.22056C21.898 6.38449 21.0343 5.8901 20.2455 6.41801L9.23432 13.7869C8.37888 14.3594 8.51343 15.5 9.43644 15.5H12.336V15.4789H17.9871L13.3825 17.0051L11.3526 23.7795C11.102 24.6155 11.9656 25.1099 12.7545 24.582L23.7657 17.2131C24.6211 16.6406 24.4865 15.5 23.5636 15.5H19.1665L21.6474 7.22056Z'
        fill={fillColor}
      />
    </svg>
  );
}

export default function SearchIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.4868 6.243C2.92398 4.37923 4.37923 2.92398 6.243 2.4868C7.589 2.17107 8.98979 2.17107 10.3358 2.4868C12.1996 2.92398 13.6548 4.37923 14.092 6.243C14.4077 7.589 14.4077 8.98979 14.092 10.3358C13.6548 12.1996 12.1996 13.6548 10.3358 14.092C8.98979 14.4077 7.589 14.4077 6.243 14.092C4.37923 13.6548 2.92398 12.1996 2.4868 10.3358C2.17107 8.98979 2.17107 7.589 2.4868 6.243Z'
        fill='#F2F4F7'
      />
      <path
        d='M2.4868 10.3358L3.21698 10.1645L2.4868 10.3358ZM2.4868 6.243L3.21698 6.41428L3.21698 6.41428L2.4868 6.243ZM14.092 6.243L14.8222 6.07173L14.8222 6.07173L14.092 6.243ZM14.092 10.3358L14.8222 10.5071L14.092 10.3358ZM10.3358 14.092L10.1645 13.3618L10.3358 14.092ZM6.243 14.092L6.07172 14.8222L6.243 14.092ZM6.243 2.4868L6.07172 1.75661L6.243 2.4868ZM10.3358 2.4868L10.5071 1.75661L10.5071 1.75661L10.3358 2.4868ZM15.2197 16.2803C15.5126 16.5732 15.9874 16.5732 16.2803 16.2803C16.5732 15.9874 16.5732 15.5126 16.2803 15.2197L15.2197 16.2803ZM3.21698 10.1645C2.92767 8.93117 2.92767 7.64762 3.21698 6.41428L1.75662 6.07172C1.41446 7.53037 1.41446 9.04842 1.75662 10.5071L3.21698 10.1645ZM13.3618 6.41428C13.6511 7.64762 13.6511 8.93117 13.3618 10.1645L14.8222 10.5071C15.1643 9.04841 15.1643 7.53037 14.8222 6.07173L13.3618 6.41428ZM10.1645 13.3618C8.93117 13.6511 7.64762 13.6511 6.41428 13.3618L6.07172 14.8222C7.53037 15.1643 9.04841 15.1643 10.5071 14.8222L10.1645 13.3618ZM6.41428 3.21698C7.64762 2.92767 8.93117 2.92767 10.1645 3.21698L10.5071 1.75661C9.04841 1.41446 7.53037 1.41446 6.07172 1.75661L6.41428 3.21698ZM6.41428 13.3618C4.82783 12.9897 3.58911 11.751 3.21698 10.1645L1.75662 10.5071C2.25885 12.6482 3.93063 14.3199 6.07172 14.8222L6.41428 13.3618ZM10.5071 14.8222C12.6482 14.3199 14.3199 12.6482 14.8222 10.5071L13.3618 10.1645C12.9897 11.751 11.751 12.9897 10.1645 13.3618L10.5071 14.8222ZM10.1645 3.21698C11.751 3.58911 12.9897 4.82782 13.3618 6.41428L14.8222 6.07173C14.3199 3.93063 12.6482 2.25885 10.5071 1.75661L10.1645 3.21698ZM6.07172 1.75661C3.93063 2.25885 2.25885 3.93063 1.75662 6.07172L3.21698 6.41428C3.58911 4.82782 4.82782 3.58911 6.41428 3.21698L6.07172 1.75661ZM12.471 13.5317L15.2197 16.2803L16.2803 15.2197L13.5317 12.471L12.471 13.5317Z'
        fill='#667085'
      />
    </svg>
  );
}
